<template>
  <div class="modal modal-overlay">
    <div :class="{preferences}" class="cookies-modal">
      <button class="close-button" @click="$emit('close')">&times;</button>
      <div class="title">{{ preferences ? 'Manage cookies settings' :  'We use Cookies' }}</div>

      <div v-if="!preferences" class="desc chekbox-label">
        We use cookies and similar technologies to ensure the core functionality of our website. They are necessary for you to browse our platform and use its features. Therefore, they are set automatically and do not require your consent.
        <br/>
        In addition, we may offer cookies that offer several benefits to enhance your experience on our platform such as personalized content, valuable insights, and other advantages. These cookies are optional, and their use depends on your preferences. Please see our <a class="title" @click="goToPage('cookie')">Cookie Notice</a> for more information.
      </div>
      <div v-else class="cookie-settings">
        <div class="cookie-item">Strictly Necessary Cookies</div>
        <div class="cookie-item">
          Performance Cookies
          <label class="switch">
            <input type="checkbox" v-model="cookies.performance">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="cookie-item">
          Functional Cookies
          <label class="switch">
            <input type="checkbox" v-model="cookies.functional">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="cookie-item">
          Targeting Cookies
          <label class="switch">
            <input type="checkbox" v-model="cookies.targeting">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="buttons">
        <button v-if="!preferences" class="button" @click="acceptCookies">Got it</button>
        <button v-else class="button" @click="acceptCookies">Confirm</button>

        <button v-if="!preferences" class="button" @click="preferences = true">Preferences</button>
        <button v-else class="button" @click="preferences = false">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieModal",
  data: () => {
    return {
      preferences: false,
      cookies: {
        performance: false,
        functional: false,
        targeting: false
      }
    }
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', JSON.stringify(this.cookies));
      this.$emit('close');
    },
  }
}
</script>

<style lang="scss" scoped>
.cookies-modal {
  position: relative;
}

.cookies-modal.preferences > .title {
  font-size: 32px;
}

.buttons {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 770px)  {
    flex-direction: column;
    align-items: center;

    .button {
      width: 100%;
    }
  }
}
.cookie-settings {
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.cookie-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  font-family: "Kanit", sans-serif;
  color: #f3f3f3;
}
.cookie-item label {
  margin-top: 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.close-button {
  position: absolute;
  top: -8px;
  right: 5px;
  background: none;
  border: none;
  font-size: 35px;
  cursor: pointer;
  color: #FFFFFF;
}
</style>
